// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-biography-js": () => import("./../../../src/templates/biography.js" /* webpackChunkName: "component---src-templates-biography-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-works-js": () => import("./../../../src/templates/works.js" /* webpackChunkName: "component---src-templates-works-js" */),
  "component---src-templates-works-work-js": () => import("./../../../src/templates/works-work.js" /* webpackChunkName: "component---src-templates-works-work-js" */)
}

